import { KpiSchemeActionId, SourceFormatKpiSettingScheme, WorkingFormatKpiSettingScheme } from '@/types/GCB2'
import { v4 as uuidv4 } from 'uuid'
import * as _ from 'lodash'
import { toStringOrNull } from '../../utils'
const kpiService = {
    mapSchemeToWorkingFormat,
    mapSchemeToSourceFormat,
    addRanges,
}

function mapSchemeToWorkingFormat(scheme: SourceFormatKpiSettingScheme): WorkingFormatKpiSettingScheme {
    const staff_action_value: WorkingFormatKpiSettingScheme['staff_action_value'] = {} as WorkingFormatKpiSettingScheme['staff_action_value']
    const record_value: WorkingFormatKpiSettingScheme['record_value'] = {} as WorkingFormatKpiSettingScheme['record_value']
    const visit_value: WorkingFormatKpiSettingScheme['visit_value'] = {} as WorkingFormatKpiSettingScheme['visit_value']
    //staff_action_value
    for (const key in scheme.staff_action_value) {
        const staff_action = scheme.staff_action_value[key as KpiSchemeActionId]
        const conversions = []
        for (const conversionValue in staff_action) {
            const bonuses = []
            for (const activationId in staff_action[conversionValue]) {
                const bonus = staff_action[conversionValue][activationId]
                bonuses.push({ id: uuidv4(), activationId, fixed: toStringOrNull(bonus.fixed) })
            }
            const conversion = {
                id: uuidv4(),
                conversion: conversionValue,
                values: bonuses,
            }
            conversions.push(conversion)
        }
        staff_action_value[key as KpiSchemeActionId] = conversions
    }
    //record_value
    const recordValueBonuses = []
    for (const activationId in scheme.record_value) {
        const { fixed } = scheme.record_value[activationId]
        recordValueBonuses.push({
            id: uuidv4(),
            activationId,
            fixed: fixed === null ? '0' : fixed.toString(),
        })
    }
    record_value.values = recordValueBonuses
    //visit_value
    for (const categoryId in scheme.visit_value) {
        const categoryConversions = scheme.visit_value[categoryId]
        const conversions = []
        for (const conversionValue in categoryConversions) {
            const bonuses = []
            for (const activationId in categoryConversions[conversionValue]) {
                const { fixed, percent } = categoryConversions[conversionValue][activationId]
                bonuses.push({
                    id: uuidv4(),
                    activationId,
                    fixed: toStringOrNull(fixed),
                    percent: toStringOrNull(percent),
                })
            }
            const conversion = {
                id: uuidv4(),
                conversion: conversionValue,
                values: bonuses,
            }
            conversions.push(conversion)
        }
        visit_value[categoryId] = conversions
    }

    return Object.assign({}, scheme, {
        staff_action_value,
        record_value,
        visit_value,
    })
}
function mapSchemeToSourceFormat(scheme: WorkingFormatKpiSettingScheme): SourceFormatKpiSettingScheme {
    const staff_action_value: SourceFormatKpiSettingScheme['staff_action_value'] = {} as SourceFormatKpiSettingScheme['staff_action_value']
    const record_value: SourceFormatKpiSettingScheme['record_value'] = {} as SourceFormatKpiSettingScheme['record_value']
    const visit_value: SourceFormatKpiSettingScheme['visit_value'] = {} as SourceFormatKpiSettingScheme['visit_value']
    //staff_action_value
    for (const staffActionId in scheme.staff_action_value) {
        const staffAction = scheme.staff_action_value[staffActionId as KpiSchemeActionId]
        staffAction.forEach(conversion => {
            conversion.values.forEach(bonus => {
                if (conversion.conversion === '' || bonus.fixed === '') {
                    return
                }
                _.setWith(
                    staff_action_value,
                    [staffActionId, conversion.conversion, bonus.activationId],
                    {
                        fixed: bonus.fixed ? parseFloat(bonus.fixed) : null,
                    },
                    Object
                )
            })
        })
    }
    //record_value
    for (const bonus of scheme.record_value.values) {
        if (bonus.fixed === '') {
            continue
        }
        _.setWith(
            record_value,
            [bonus.activationId],
            {
                fixed: bonus.fixed ? parseFloat(bonus.fixed) : null,
            },
            Object
        )
    }
    //visit_value
    for (const categoryId in scheme.visit_value) {
        const categoryConversions = scheme.visit_value[categoryId]
        categoryConversions.forEach(conversion => {
            conversion.values.forEach(bonus => {
                if (conversion.conversion === '' || (bonus.percent === '' && bonus.fixed === '')) {
                    return
                }
                _.setWith(
                    visit_value,
                    [categoryId, conversion.conversion, bonus.activationId],
                    {
                        fixed: bonus.fixed ? parseFloat(bonus.fixed) : null,
                        percent: bonus.percent ? parseFloat(bonus.percent) : null,
                    },
                    Object
                )
            })
        })
    }
    return Object.assign({}, scheme, {
        staff_action_value,
        record_value,
        visit_value,
    })
}
function addRanges(scheme: WorkingFormatKpiSettingScheme): WorkingFormatKpiSettingScheme {
    let convertion_range_sa: WorkingFormatKpiSettingScheme['convertion_range_sa'] = [] as WorkingFormatKpiSettingScheme['convertion_range_sa']
    let revenue_range_visit: WorkingFormatKpiSettingScheme['revenue_range_visit'] = [] as WorkingFormatKpiSettingScheme['revenue_range_visit']

    for (const actionId in scheme.staff_action_value) {
        const conversions = scheme.staff_action_value[actionId as KpiSchemeActionId]
        conversions.forEach(el => {
            convertion_range_sa.push(parseFloat(el.conversion))
        })
    }
    for (const categoryId in scheme.visit_value) {
        const conversions = scheme.visit_value[categoryId]
        conversions.forEach(el => {
            revenue_range_visit.push(parseFloat(el.conversion))
        })
    }

    convertion_range_sa = _.uniq(convertion_range_sa.filter(el => el))
    revenue_range_visit = _.uniq(revenue_range_visit.filter(el => el))
    return Object.assign({}, scheme, {
        convertion_range_sa,
        revenue_range_visit,
    })
}

export default kpiService
