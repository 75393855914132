import { Ref, ref } from 'vue'

export interface TrackCodeExecutionError {
    stack: string | null
    message: string | null
    name: string | null
}

export const useTrackCodeExecution = <T>(
    trackCodeExecutionCallback: () => Promise<T>,
    options?: { onError?: (error: TrackCodeExecutionError) => void; isLoadingDefault?: boolean }
) => {
    const result = ref(null) as Ref<T | null>
    const isLoading = ref<boolean>(options?.isLoadingDefault ? true : false)
    const error = ref<TrackCodeExecutionError | null>(null)

    const run = async (): Promise<void> => {
        try {
            isLoading.value = true
            error.value = null
            const currentResult = await trackCodeExecutionCallback()
            result.value = currentResult
        } catch (e) {
            let currentError: TrackCodeExecutionError = { message: null, name: null, stack: null }
            if (e instanceof Error) {
                currentError = { message: e.message, stack: e.stack ?? null, name: e.name }
            } else {
                currentError = { message: 'неизвестная ошибка', name: null, stack: null }
            }
            error.value = currentError
            result.value = null
            if (options?.onError) {
                options?.onError(currentError)
            }
        } finally {
            isLoading.value = false
        }
    }

    return {
        result,
        isLoading,
        error,
        run,
    }
}
