
import * as _ from 'lodash'
import { defineComponent, ref, PropType, computed, nextTick, watch, getCurrentInstance } from 'vue'
import { KpiSchemeActionId, WorkingFormatKpiSetting } from '@/types/GCB2'
import BaseInputOutlined from '../../../../../inputs/BaseInputOutlined.vue'
import { v4 as uuidv4 } from 'uuid'

export default defineComponent({
    name: 'KpiStaffActionsConvertions',
    components: { BaseInputOutlined },
    props: {
        value: {
            type: Object as PropType<WorkingFormatKpiSetting['scheme']['staff_action_value']>,
            required: true,
        },
        selectedActionId: { type: String as PropType<KpiSchemeActionId>, required: true },
    },
    setup(props, { emit, }) {
        const root = getCurrentInstance()!.proxy
        const conversions = computed<any>({
            get() {
                return props.value[props.selectedActionId]
            },
            set(newValue) {
                emit('input', Object.assign({}, props.value, { [props.selectedActionId]: newValue }))
            },
        })
        const activeConversion = ref(conversions.value[0])
        const helpText = `Конверсия сотрудников — это доля коммуникаций, которые приводят к записям. Чем выше конверсия, тем чаще после звонка или сообщения сотрудника клиенты записываются к вам.`
        watch(
            activeConversion,
            () => {
                emit('select', activeConversion.value)
            },
            { immediate: true }
        )
        watch(
            () => props.selectedActionId,
            () => {
                activeConversion.value = conversions.value[0]
            }
        )
        const getConversionPercent = (conversion: string) => {
            const val = ['', 'NaN'].includes(conversion) ? '' : (Number(conversion) * 100).toFixed(0) + '%'
            return val
        }
        const getConversionValue = (val: string) =>
            val.trim() === '' ? '' : String(parseFloat(val.replace('%', '').trim()) / 100)
        const sortConvertions = () => {
            conversions.value = conversions.value.sort((a: any, b: any) => {
                if (a.conversion.trim() === '') {
                    return 1
                }
                if (b.conversion.trim() === '') {
                    return -1
                }

                return parseFloat(a.conversion) - parseFloat(b.conversion)
            })
        }

        const onConversionBlur = (event: Event, conversion: any) => {
            const otherConversions = conversions.value.filter((el: any) => el.id !== conversion.id)
            const otherConversionsValues = otherConversions.map((el: any) => el.conversion)
            if (
                otherConversionsValues.includes(String(conversion.conversion)) ||
                String(conversion.conversion).trim() === ''
            ) {
                conversion.conversion = ''
                const target = event.target as HTMLInputElement
                target.classList.remove('conversion-input-error')
            }

            sortConvertions()
        }
        const onConversionInput = (event: Event, conversion: any) => {
            const otherConversions = conversions.value.filter((el: any) => el.id !== conversion.id)
            const otherConversionsValues = otherConversions.map((el: any) => el.conversion)
            const target = event.target as HTMLInputElement
            const val = getConversionValue(target.value)

            if (otherConversionsValues.includes(String(val))) {
                target.classList.add('conversion-input-error')
            } else {
                target.classList.remove('conversion-input-error')
            }
        }
        const updateConversion = (event: Event, conversion: any) => {
            const target = event.target as HTMLInputElement
            const val = getConversionValue(target.value)
            conversion.conversion = val
        }
        const addConversion = () => {
            const newConversion = {
                id: uuidv4(),
                conversion: '',
                values: [
                    {
                        id: uuidv4(),
                        activationId: 'default',
                        fixed: 0,
                    },
                ],
            }
            conversions.value.push(newConversion)
            const refName = `staffActionConversionInput-${newConversion.id}`

            nextTick(() => {
                activeConversion.value = newConversion
                ;(root.$refs as any)[refName][0].focus()
            })
        }
        const deleteConversion = (conversion: any) => {
            const delIndex = conversions.value.findIndex((el: any) => el.id === conversion.id)
            conversions.value = conversions.value.filter((el: any) => el.id !== conversion.id)
            if (activeConversion.value.id === conversion.id) {
                activeConversion.value = conversions.value[delIndex - 1]
            }
        }

        const onCreated = () => {
            sortConvertions()
        }
        onCreated()

        return {
            conversions,
            activeConversion,
            helpText,
            getConversionPercent,
            updateConversion,
            onConversionBlur,
            onConversionInput,
            addConversion,
            deleteConversion,
        }
    },
})
