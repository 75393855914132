
import * as _ from 'lodash'
import {
    defineComponent,
    ref,
    PropType,
    computed,
    nextTick,
    watch,
    shallowRef,
    Ref,
    getCurrentInstance,
} from 'vue'
import { KpiSchemeActionId, WorkingFormatKpiSetting } from '@/types/GCB2'
import BaseInputOutlined from '../../../../../inputs/BaseInputOutlined.vue'
import { v4 as uuidv4 } from 'uuid'
import CustomActivatorSelect from '../../../../../inputs/CustomActivatorSelect.vue'
import { kpiSettingsGetActivations } from '../../../../../../api/chisai/GCB2'
import useRouter from '../../../../../../hooks/useRouter'
import { encodePacket } from 'engine.io-parser'
import { checkInputIsNumber } from '../../../../../../utils'

export default defineComponent({
    name: 'KpiStaffActionsActivations',
    components: { BaseInputOutlined, CustomActivatorSelect },
    props: {
        value: {
            type: Array as PropType<
                {
                    id: string
                    activationId: string
                    fixed: number
                }[]
            >,
            required: true,
        },
        activationsList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter(root)
        const sortedConversionsActivation = computed(() => {
            const sorted = [...props.value].sort((a, b) => {
                if (a.activationId === 'default') {
                    return -1
                }
                if (b.activationId === 'default') {
                    return 1
                }
                return 0
            })
            return sorted
        })
        const existedActivationsIds = computed(() => props.value.map(el => el.activationId))
        const getAvailableActivationsList = (activationId: string | null) => {
            return props.activationsList.map(el =>
                Object.assign({}, el, {
                    disabled:
                        activationId === el.id
                            ? false
                            : el.id === 'default' || existedActivationsIds.value.includes(el.id),
                })
            )
        }
        const updateActivationConversion = (activationConversion: any, updFields: Object) => {
            const clone = [...props.value]
            const deleteIndex = clone.findIndex(el => el.id === activationConversion.id)
            if (deleteIndex > -1) {
                clone.splice(deleteIndex, 1, Object.assign({}, activationConversion, updFields))
                emit('input', clone)
            }
        }
        const deleteActivationConversion = (activationConversion: any) => {
            const clone = [...props.value]
            emit(
                'input',
                clone.filter(el => el.id !== activationConversion.id)
            )
        }
        const openCreateMenu = () => {
            ;(root.$refs as any)['createSelect'].openMenu()
        }
        const createActivationConversion = (activationId: string) => {
            const newActivationConversion = {
                id: uuidv4(),
                activationId,
                fixed: 0,
            }
            const clone = [...props.value]
            clone.push(newActivationConversion)
            emit('input', clone)
        }
        const onCreated = async () => {}
        onCreated()
        return {
            createActivationConversion,
            sortedConversionsActivation,
            getAvailableActivationsList,
            updateActivationConversion,
            deleteActivationConversion,
            openCreateMenu,
            checkInputIsNumber,
        }
    },
})
