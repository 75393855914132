import { render, staticRenderFns } from "./RecordValueStep.vue?vue&type=template&id=9c813cbe&scoped=true"
import script from "./RecordValueStep.vue?vue&type=script&lang=ts"
export * from "./RecordValueStep.vue?vue&type=script&lang=ts"
import style0 from "./RecordValueStep.vue?vue&type=style&index=0&id=9c813cbe&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c813cbe",
  null
  
)

export default component.exports