
import { defineComponent, ref, PropType, getCurrentInstance } from 'vue'
import { WorkingFormatKpiSetting } from '@/types/GCB2'
import TextField from '../../../../inputs/TextField.vue'
import { KPI_DESCRIPTION_MAX_LENGTH, KPI_NAME_MAX_LENGTH } from '@/vars/GCB2'
export default defineComponent({
    name: 'KpiNameAndDescription',
    components: { TextField },
    props: {
        value: { type: Object as PropType<WorkingFormatKpiSetting>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const rules = {
            name: [
                (v: any) => !!v || 'Обязательно',
                (v: any) => v.length <= KPI_NAME_MAX_LENGTH || `Меньше ${KPI_NAME_MAX_LENGTH} символов`,
            ],
            description: [
                (v: any) =>
                    v.length <= KPI_DESCRIPTION_MAX_LENGTH || `Меньше ${KPI_DESCRIPTION_MAX_LENGTH} символов`,
            ],
        }

        const formInput = (e: any) => emit('validation-updated', e)

        const clear = () => {
            {
                console.log('root.$refs', root.$refs)
                ;(root.$refs as any).form.resetValidation()
            }
        }

        return { formInput, clear, rules }
    },
})
