
import { defineComponent, PropType, ref, computed, toRefs, watch, getCurrentInstance } from 'vue'
import { KpiCategory } from '@/types/GCB2'
import { useKpiStore } from '../../../../../../store/stores/gcb2/kpi'
import * as _ from 'lodash'
import TextField from '../../../../../inputs/TextField.vue'
import { Project } from '../../../../../../types/main'
import {
    getServiceList,
    kpiSettingsDeleteServiceCategory,
    kpiSettingsSaveServiceCategories,
} from '../../../../../../api/chisai/GCB2'
import CustomActivatorSelect from '../../../../../inputs/CustomActivatorSelect.vue'
import SpinnerLoader from '../../../../../SpinnerLoader.vue'
const getEmptyCategory = (projectId: string): KpiCategory => ({
    id: '',
    name: '',
    projectId,
    values: [],
})
export default defineComponent({
    name: 'KpiCategoryModal',
    components: { TextField, CustomActivatorSelect, SpinnerLoader },
    props: {},
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const project = computed(
            (): Project => {
                return root.$store.getters.projectById(root.$router.currentRoute.params.id)
            }
        )

        const kpiStore = useKpiStore()
        const { categoryModalState, activeModalCategoryId, categoryModalIsOpen } = toRefs(kpiStore)
        const category = ref<KpiCategory>({} as KpiCategory)
        const categories = ref<KpiCategory[]>([])
        const loading = ref(false)
        const validName = ref(true)
        const nameRules = [
            (v: any) => !!v || 'Обязательно',
            (v: any) => v.length <= 100 || `Должно быть меньше 100 символов`,
            (v: any) => v !== 'default' || `Недопустимое название`,
            (v: any) => !otherCategoriesName.value.includes(v) || `Группа уже существует`,
        ]
        const buttonsLoading = ref(false)
        const otherCategories = computed(() => categories.value.filter(el => el.id !== category.value.id))
        const otherCategoriesName = computed(() => otherCategories.value.map(el => el.name))
        const allAttachedServices = computed(() => otherCategories.value.map(el => el.values).flat())
        const allAttachedServicesIds = computed(() => allAttachedServices.value.map(el => el.service_id))
        const serviceList = ref<any[]>([])
        const currentCategoryServiceIds = computed(() => category.value.values.map(el => el.service_id))
        const title = computed(() =>
            categoryModalState.value === 'create' ? 'НОВАЯ ГРУППА УСЛУГ' : 'НАСТРОЙКИ ГРУППЫ УСЛУГ'
        )
        const valid = computed(() => validName.value && category.value.values.length)
        const availableServices = computed(() =>
            serviceList.value.map(el =>
                Object.assign({}, el, { disabled: allAttachedServicesIds.value.includes(el.id) })
            )
        )

        const onNameInput = (val: string) => {
            if (otherCategoriesName.value.includes(val)) {
                return
            }
            category.value.name = val
            category.value.id = val
            category.value.values.map(el => Object.assign(el, { dh_service_category: val }))
        }
        const clearNameInput = () => {
            category.value.name = ''
            category.value.id = ''
            category.value.values.map(el => Object.assign(el, { dh_service_category: '' }))
        }

        watch(
            categoryModalIsOpen,
            () => {
                if (root.$refs.form) {
                    //@ts-ignore
                    root.$refs.form.resetValidation()
                }
                categories.value = _.cloneDeep(
                    kpiStore.categories.filter(el => el.projectId === project.value.id)
                )
                category.value =
                    categoryModalState.value === 'create'
                        ? getEmptyCategory(project.value.id)
                        : (categories.value.find(el => el.id === activeModalCategoryId.value) as KpiCategory)
            },
            { immediate: true }
        )
        const updateCategory = (e: any) => {
            category.value = Object.assign({}, category.value, {
                values: e.map((serviceId: string) => ({
                    project_id: category.value.projectId,
                    service_id: serviceId,
                    dh_service_category: category.value.id,
                    extra: null,
                })),
            })
        }
        const openMenu = () => {
            ;(root.$refs as any).categorySelect.openMenu()
        }
        const getCategoryByServiceId = (serviceId: string) => {
            return categories.value.find(el => el.values.map(el => el.service_id).includes(serviceId))
        }
        const getCategoryNameByServiceId = (serviceId: string) => {
            return getCategoryByServiceId(serviceId)?.name
        }
        const detachService = (serviceId: string) => {
            const relatedCategoryId = allAttachedServices.value.find(el => el.service_id === serviceId)
                ?.dh_service_category
            if (!relatedCategoryId) {
                return
            }

            const categoriesClone = [...categories.value]
            const categoryIndex = categories.value.findIndex(el => el.id === relatedCategoryId)
            const category = Object.assign({}, categories.value[categoryIndex])
            category.values = category.values.filter(el => el.service_id !== serviceId)
            categoriesClone.splice(categoryIndex, 1, category)
            categories.value = categoriesClone
        }
        const getServiceName = (serviceId: string) => {
            return serviceList.value.find(el => el.id === serviceId)?.name
        }
        const removeServiceFromCategory = (serviceId: string) => {
            category.value = Object.assign({}, category.value, {
                values: category.value.values.filter(el => el.service_id !== serviceId),
            })
        }

        const saveCategories = async () => {
            try {
                buttonsLoading.value = true
                const categories = [...otherCategories.value].concat(category.value)
                const savedCategories = await kpiSettingsSaveServiceCategories({
                    projectId: project.value.id,
                    categories,
                }).then(res => res.data as KpiCategory[])
                kpiStore.categories = kpiStore.categories
                    .filter(el => el.projectId !== project.value.id)
                    .concat(savedCategories)
                emit('update-category-id', category.value.id)
                kpiStore.activeModalCategoryId = category.value.id
            } catch (err) {
                console.error(err)
            } finally {
                buttonsLoading.value = false
                kpiStore.categoryModalIsOpen = false
            }
        }
        const deleteCategory = async () => {
            kpiStore.categoryModalIsOpen = false
            emit('delete-category', category.value.id)
        }

        const onCreated = async () => {
            loading.value = true
            serviceList.value = await getServiceList({ projectId: project.value.id }).then(
                res => res.data ?? []
            )
            loading.value = false
        }
        onCreated()
        return {
            kpiStore,
            title,
            category,
            categories,
            serviceList,
            nameRules,
            validName,
            categoryModalState,
            otherCategoriesName,
            valid,
            currentCategoryServiceIds,
            availableServices,
            allAttachedServicesIds,
            buttonsLoading,
            loading,
            onNameInput,
            clearNameInput,
            getServiceName,
            removeServiceFromCategory,
            updateCategory,
            detachService,
            getCategoryNameByServiceId,
            saveCategories,
            deleteCategory,
            openMenu,
        }
    },
})
