
import * as _ from 'lodash'
import { defineComponent, ref, PropType, computed, Ref } from 'vue'
import { WorkingFormatKpiSetting } from '@/types/GCB2'
import TextField from '@/components/inputs/TextField.vue'
import { DEFAULT_KPI_SETTING, KPI_DESCRIPTION_MAX_LENGTH, KPI_NAME_MAX_LENGTH } from '@/vars/GCB2'
import Activations from './Activations.vue'
import kpiService from '../../../../../../helpers/gcb2/kpi'
export default defineComponent({
    name: 'KpiRecordValueStep',
    components: { TextField, Activations },
    props: {
        value: { type: Object as PropType<WorkingFormatKpiSetting>, required: true },
        activationsList: { type: Array as PropType<{ id: string; name: string }[]> },
    },
    setup(props, { emit }) {
        const formInput = (e: any) => emit('validation-updated', e)
        const clear = () => {
            const clone = Object.assign({}, props.value)
            _.set(
                clone,
                ['scheme', 'record_value'],
                kpiService.mapSchemeToWorkingFormat(DEFAULT_KPI_SETTING.scheme).record_value
            )
            emit('input', clone)
        }

        return { formInput, clear }
    },
})
