import { defineStore } from 'pinia'

import _ from 'lodash'
import { kpiSettingsDeleteServiceCategory, kpiSettingsGetServiceCategories } from '../../../api/chisai/GCB2'
type CategoryModalState = 'create' | 'edit'
import { KpiCategory } from '@/types/GCB2'
export const useKpiStore = defineStore('gcb2KpiStore', {
    state: () => ({
        categoryModalIsOpen: false as boolean,
        categoryModalState: 'create' as CategoryModalState,
        activeModalCategoryId: undefined as string | undefined,
        startCategories: [] as KpiCategory[], //readonly
        categories: [] as KpiCategory[],
    }),
    actions: {
        openCategoryModal({ state, categoryId }: { state: CategoryModalState; categoryId?: string }) {
            this.categoryModalState = state
            this.categoryModalIsOpen = true
            this.activeModalCategoryId = categoryId
        },
        closeCategoryModal() {
            this.categoryModalIsOpen = false
        },
        async fetchCategories(projectId: string) {
            this.categories = await kpiSettingsGetServiceCategories({ projectId }).then(
                res => res.data as KpiCategory[]
            )
            this.startCategories = _.cloneDeep(this.categories)
        },
        async deleteCategory({ projectId, categoryId }: any) {
            const savedCategories = await kpiSettingsDeleteServiceCategory({
                projectId,
                categoryId,
            }).then(res => res.data as KpiCategory[])
            this.categories = this.categories.filter(el => el.projectId !== projectId).concat(savedCategories)
        },
    },
    getters: {
        getCategoryById: state => ({
            projectId,
            id,
        }: {
            projectId: string
            id: string
        }): KpiCategory | undefined => {
            return state.categories.find(el => el.projectId === projectId && el.id === id)
        },
        getCategories: state => (projectId: string) => {
            return state.categories.filter(el => el.projectId === projectId)
        },
    },
})
