
import * as _ from 'lodash'
import { defineComponent, ref, PropType, computed, watch, Ref, getCurrentInstance } from 'vue'
import { WorkingFormatKpiSetting } from '@/types/GCB2'
import TextField from '@/components/inputs/TextField.vue'
import { DEFAULT_KPI_SETTING, KPI_DESCRIPTION_MAX_LENGTH, KPI_NAME_MAX_LENGTH } from '@/vars/GCB2'
import Activations from './Activations.vue'
import Conversions from './Conversions.vue'
import kpiService from '../../../../../../helpers/gcb2/kpi'
import { useKpiStore } from '../../../../../../store/stores/gcb2/kpi'
import { v4 as uuidv4 } from 'uuid'
import CustomActivatorSelect from '../../../../../inputs/CustomActivatorSelect.vue'
import CategoryModal from './CategoryModal.vue'
import { Project } from '../../../../../../types/main'

const getDefaultConversion = (activationId: string) => {
    return {
        id: uuidv4(),
        conversion: '0',
        values: [
            {
                id: uuidv4(),
                activationId,
                fixed: 0,
                percent: 0,
            },
        ],
    }
}

export default defineComponent({
    name: 'KpiVisitValueStep',
    components: { TextField, Conversions, Activations, CustomActivatorSelect, CategoryModal },
    props: {
        value: { type: Object as PropType<WorkingFormatKpiSetting>, required: true },
        activationsList: { type: Array as PropType<{ id: string; name: string }[]>, required: true },
    },
    setup(props, { emit }) {
        const root = getCurrentInstance()!.proxy
        const kpiStore = useKpiStore()
        const selectedConversion = ref()
        const selectedCategoryId = ref('default')
        const project = computed(
            (): Project => {
                return root.$store.getters.projectById(root.$router.currentRoute.params.id)
            }
        )
        const categories = computed(() => kpiStore.getCategories(project.value.id))
        watch(
            selectedCategoryId,
            categoryId => {
                if (!props.value.scheme.visit_value[categoryId]) {
                    const newConversion = getDefaultConversion('default')
                    const clone = Object.assign({}, props.value)
                    _.set(clone, ['scheme', 'visit_value', categoryId], [newConversion])
                    emit('input', clone)
                }
            },
            { immediate: true }
        )
        const formInput = (e: any) => emit('validation-updated', e)
        const clear = () => {
            const clone = Object.assign({}, props.value)
            const visitValue = {} as any
            kpiStore.categories
                .map(el => el.id)
                .forEach(activationId => {
                    visitValue[activationId] = [getDefaultConversion(activationId)]
                })
            _.set(clone, ['scheme', 'visit_value'], visitValue)
            emit('input', clone)
        }
        const deleteCategory = async (categoryId: string) => {
            if (selectedCategoryId.value === categoryId) {
                selectedCategoryId.value = 'default'
            }
            await kpiStore.deleteCategory({ projectId: project.value.id, categoryId })
            const clone = Object.assign({}, props.value)
            _.unset(clone, ['scheme', 'visit_value', categoryId])
            emit('input', clone)
        }
        const updateSelectedCategoryId = (categoryId: string) => {
            selectedCategoryId.value = categoryId
        }
        const editCategory = (categoryId: string) => {
            kpiStore.openCategoryModal({ state: 'edit', categoryId })
        }
        return {
            formInput,
            clear,
            deleteCategory,
            editCategory,
            updateSelectedCategoryId,
            selectedConversion,
            selectedCategoryId,
            kpiStore,
            categories,
        }
    },
})
